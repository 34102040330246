import { useScreenSizeContext } from "@bluebottlecoffee/design-system/components";
import { AlgoliaCafe } from "../algolia/types";
import { CafeMarker } from "../../components/CafeSearch/CafeSearchMap";

/** Geo coordinates of the center of the contiguous USA */
export const centerOfUSA: google.maps.LatLngLiteral = {
  lat: 39.8283,
  lng: -96.5795,
};

export const centerOfUSAStr: string = "39.8283, -96.5795";

/** geopints containing the contiguous US */
export const boundingBoxUS = {
  east: 3.16723093729438,
  north: -136.4545344164958,
  south: 62.91741056183184,
  west: -63.68109691649579,
};

/** convert google maps LatLngLiteral object into a string */
export const boundsToStr = (bounds: google.maps.LatLngBounds): string => {
  const ne = bounds?.getNorthEast();
  const sw = bounds?.getSouthWest();
  return `${sw?.lat()}, ${sw?.lng()}, ${ne?.lat()}, ${ne?.lng()}`;
};

/** transform AlgoliaCafe type to CafeMarker type */
export const hitToCafeMarker = (
  hit: AlgoliaCafe,
  lang: string,
): CafeMarker => ({
  name: hit.name[lang],
  position: {
    lat: hit.geoLocation.lat,
    lng: hit.geoLocation.lng,
  },
});

/** convert the geometry string into a google maps LatLngLiteral object */
export const geoStrToLatLngLiteral = (
  geo?: string,
): google.maps.LatLngLiteral | undefined => {
  const latLng: string[] | undefined = geo?.split(",");
  const lat: number | undefined = parseFloat(latLng?.[0]);
  const lng: number | undefined = parseFloat(latLng?.[1]);
  return lat && lng ? { lat, lng } : undefined;
};

/** get the distance between 2 geopoints using trigonometry */
export const getGeoDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
): number => {
  // convert degrees to radians
  const radlat1 = (Math.PI * lat1) / 180;
  const radlat2 = (Math.PI * lat2) / 180;
  const theta = lon1 - lon2;
  const radtheta = (Math.PI * theta) / 180;
  let dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  // convert back to degrees
  dist = (dist * 180) / Math.PI;
  // convert degrees to miles
  dist = dist * 60 * 1.1515;
  // convert miles to kilometers
  dist *= 1.609344;
  return dist;
};

/**
 * search is limited to the 5 countries where we have cafes
 * (us, lp, kr, hk, cn) so we explicitly define the smaller countries
 * for zoom purposes.
 */

const zoomLevels = {
  street_address: 14,
  route: 13,
  intersection: 13,
  political: 6,
  country: 5,
  administrative_area_level_1: 6,
  administrative_area_level_2: 7,
  administrative_area_level_3: 8,
  administrative_area_level_4: 8,
  administrative_area_level_5: 8,
  administrative_area_level_6: 8,
  administrative_area_level_7: 8,
  colloquial_area: 9,
  locality: 11,
  sublocality: 12,
  sublocality_level_1: 12,
  sublocality_level_2: 12,
  sublocality_level_3: 12,
  sublocality_level_4: 12,
  sublocality_level_5: 12,
  neighborhood: 13,
  premise: 14,
  subpremise: 14,
  plus_code: 14,
  postal_code: 13,
  natural_feature: 9,
  airport: 9,
  park: 10,
  point_of_interest: 10,
  /**
   * (us, lp, kr, hk, cn) we explicitly define the smaller countries
   * for zoom purposes.
   */
  "hong kong": 13,
  japan: 6,
  korea: 7,
};

/** determine the initial zoom level of the map based on the query and viewport */
export const getInitialMapZoom = (
  queryTypes?: string[],
  query?: string,
): number => {
  const { isDesktop, isLgTablet, isMobile, isSmTablet, is2Xl } =
    useScreenSizeContext();

  const getDefaultZoom = () => {
    if (isMobile()) return 3;
    if (isSmTablet()) return 4;
    if (isLgTablet()) return 3.5;
    if (is2Xl()) return 4.25; // 2XL must come before Desktop
    if (isDesktop()) return 3.75;
    return 2.75;
  };

  const zoomAdjustment = isMobile() || isLgTablet() ? 1 : 0;

  if (!queryTypes) {
    return getDefaultZoom();
  }

  if (queryTypes.includes("country")) {
    return (zoomLevels[query?.toLowerCase() || ""] ?? 4) - zoomAdjustment;
  }

  return Math.max(
    ...queryTypes.map((type) => (zoomLevels[type] || 4) - zoomAdjustment),
  );
};

export type routerQuery = {
  [key: string]: string;
};

/** update the url with new query */
export const handleUpdateURL = (
  center: google.maps.LatLngLiteral,
  query: string,
  queryTypes: string,
) => {
  const newGeometry = `${center.lat}, ${center.lng}`;

  const latitude = center.lat;
  const longitude = center.lng;

  const isLatitude = latitude >= -180 && latitude <= 180;
  const isLongitude = longitude >= -180 && longitude <= 180;

  if (!isLatitude && !isLongitude) return null;

  const url = new URL(window.location.href);

  if (query) {
    url.searchParams.set("query", query);
  }

  url.searchParams.set("geometry", newGeometry);
  url.searchParams.set("queryTypes", queryTypes);
  return url;
};
