import {
  CafeLocatorProps,
  CafeLocatorResults,
} from "@bluebottlecoffee/design-system/components";
import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useConfigure, UseConfigureProps, useHits } from "react-instantsearch";
import { MapEvent } from "@vis.gl/react-google-maps";
import { CafeMarker } from "./CafeSearchMap";
import {
  boundsToStr,
  geoStrToLatLngLiteral,
  getGeoDistance,
  getInitialMapZoom,
  handleUpdateURL,
  hitToCafeMarker,
  routerQuery,
} from "../../lib/component-utils/cafe-search-fns";
import { AlgoliaCafe } from "../../lib/algolia/types";
import { CafeSearchResult } from "./CafeSearchResult";
import { CafeLocatorFullCopy } from "../../lib/transformers/cafe-locator";
import { CafeSearchMapWithProvider } from "./CafeSearchMapWithProvider";

interface CafeSearchHitsProps {
  copy: CafeLocatorFullCopy;
  locationSearchFormProps: CafeLocatorProps["locationSearchFormProps"];
  lang: string;
  region: string;
}

export const CafeSearchHits: FunctionComponent<CafeSearchHitsProps> = ({
  copy,
  locationSearchFormProps,
  lang,
  region,
}) => {
  const router = useRouter();
  const { query, geometry: qGeo, queryTypes } = router.query as routerQuery;

  const [boundingBox, setBoundingBox] = useState<string>("");
  const [isLoadingBoundingBox, setIsLoadingBoundingBox] =
    useState<boolean>(true);

  const [geometry, setGeometry] = useState<string>(qGeo);
  const [queryPresentation, setQueryPresentation] = useState(query);

  const { hits } = useHits<AlgoliaCafe>();

  const cardListWrapperRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<MapEvent<unknown>>(null);

  // Set bounding box
  useConfigure({
    insideBoundingBox: boundingBox,
  } as UseConfigureProps);

  const latLngLiteral = useMemo(() => {
    const geometryFormat = geometry?.replace(/[()]/g, "");
    return geoStrToLatLngLiteral(geometryFormat);
  }, [geometry]);

  /**
   * calculate the distance between the geometry and each hit and
   * add the dist prop to the hits objects
   */
  const hitsWithDist = hits.map((hit) => {
    const dist = getGeoDistance(
      hit.geoLocation.lat,
      hit.geoLocation.lng,
      latLngLiteral?.lat,
      latLngLiteral?.lng,
    );
    return { ...hit, dist };
  });

  /** sort the hits by ascending distance from the query */
  const sortedHits = hitsWithDist.sort((a, b) => a.dist - b.dist);

  const cafes = hitsWithDist.map((hit) => (
    <CafeSearchResult
      key={hit.objectID}
      cafeCardProps={{
        imageAriaLabel: copy.imgLinkGenericAriaText,
        newWindowWarning: copy.newWindowWarning,
      }}
      cafeHoursCopy={{
        opens: copy.opens,
        openTil: copy.openTil,
      }}
      algoliaHit={hit}
      isModule={false}
      lang={lang}
      region={region}
      isCafeSearch
    />
  ));

  const cafeMarkers: CafeMarker[] = sortedHits.map((hit) =>
    hitToCafeMarker(hit, lang),
  );

  const initialZoom: number = getInitialMapZoom(queryTypes?.split(","), query);

  const handleOnIdle = (map: MapEvent<unknown>) => {
    mapRef.current = map;
    setBoundingBox(boundsToStr(map?.map?.getBounds()));

    const center = {
      lat: map?.map?.getCenter().lat(),
      lng: map?.map?.getCenter().lng(),
    };

    if (isLoadingBoundingBox) return;
    const url = handleUpdateURL(center, queryPresentation, queryTypes);
    router.replace(url, undefined, { shallow: true });
  };

  const mapComponent: JSX.Element = (
    <CafeSearchMapWithProvider
      cafeMarkers={cafeMarkers}
      initialZoom={initialZoom}
      initialCenter={latLngLiteral}
      cafeMarkerSelected={queryPresentation}
      onMapSelectedMarkerChange={setQueryPresentation}
      onIdle={handleOnIdle}
    />
  );

  // set scroll to top of card list when map marker is selected
  useEffect(() => {
    if (cardListWrapperRef.current) {
      cardListWrapperRef.current.scrollTop = 0;
    }
  }, [queryPresentation]);

  // keep local state updated with url query
  useEffect(() => {
    setGeometry(qGeo);
    setQueryPresentation(query);
  }, [router]);

  useEffect(() => {
    // We don't know when hits will return the last version of hits
    const idTimeOut = setTimeout(() => {
      setIsLoadingBoundingBox(false);
    }, 500);

    return () => {
      clearTimeout(idTimeOut);
    };
  }, [boundingBox, hits, geometry, router]);

  return (
    <CafeLocatorResults
      isLoading={isLoadingBoundingBox}
      copy={copy}
      locationSearchFormProps={{
        ...locationSearchFormProps,
        onSelectPrediction: (prediction) => {
          setIsLoadingBoundingBox(true);
          const data = locationSearchFormProps.onSelectPrediction(prediction);
          return data;
        },
        onSearchSubmit: (input) => {
          setIsLoadingBoundingBox(true);
          locationSearchFormProps.onSearchSubmit(input);
          mapRef.current?.map.setZoom(initialZoom);
        },
      }}
      cardListWrapperRef={cardListWrapperRef}
      mapContainer={mapComponent}
      searchResults={{
        cafes,
        ctaViewMore: copy.viewMoreText,
        noCafesDescription: copy.noCafesDescription,
        noCafesHeadline: copy.noCafesHeadline,
      }}
      searchResultsText={
        queryPresentation
          ? `${copy.showingResults} "${queryPresentation}"`
          : `${copy.noCafesDescription}.`
      }
    />
  );
};
