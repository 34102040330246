import { APIProvider } from "@vis.gl/react-google-maps";
import { FunctionComponent, useEffect, useState } from "react";
import { CafeSearchMap, CafeSearchMapProps } from "./CafeSearchMap";

/** Wraps the Map component in APIProvider for react-google-maps */
export const CafeSearchMapWithProvider: FunctionComponent<
  CafeSearchMapProps
> = ({ ...cafeSearchMapProps }) => {
  // force rerender to account for google script loading race condition
  // TODO: remove this when we solve the race condition
  const [isMount, setIsMount] = useState(false);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsMount(true);
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }, []);

  return (
    isMount && (
      <APIProvider // for google map components in CafeSearchMap
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY}
        authReferrerPolicy="origin"
        libraries={["places", "maps", "geometry"]}
      >
        <CafeSearchMap {...cafeSearchMapProps} />
      </APIProvider>
    )
  );
};
