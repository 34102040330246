import { format } from "date-fns";
import { CafeHoursCopy } from "../transformers/cafe-card";
import { CafeHoursModel } from "../integrations/op/models/cafe-hours-model";

/**
 * Normally, Sunday is 0, but we want it to be 6. Adding 6 and modding by 7
 * results in everything shifting up by 1 and Sunday being 6.
 * Monday: 1 + 6 -> 7 % 7 = 0
 * Tuesday: 2 + 6 -> 8 % 7 = 1
 * ...
 * Sunday: 0 + 6 -> 6 % 7 = 6
 * @param day
 * @returns
 */
export const getMonThruSunDay = (day: number): number => (day + 6) % 7;

/** Get a float number representation of the hours and minutes of a datetime
 * string for comparative purposes.
 *
 * @param {string | undefined} dateTime - ie: "2024-04-08T20:05:00-04:00"
 * @returns {number | undefined} - ie: 20.05
 */
const getLocationLocalTime = (dateTime?: string): number | undefined => {
  const time: string | undefined = dateTime
    ?.split("T")[1]
    .replace(":", ".")
    .split(":")[0];
  return time ? parseFloat(time) : undefined;
};

/** Get the hoursPresentation string for a cafe
 *
 * Taking in the cafe's open and close hours in the cafe's local time, we must
 * compare that to the user's local "now" time to determine if the cafe is
 * pre-open, currently open, or post close for the day. We then return a string
 * that displays the next useful event (ie: "Open until <today's close time>" or
 * "Opens <next open day & time>"). We must consider timezone's that result in
 * full day offsets as well as days that cafes are closed for various reasons.
 *
 * We do not store the cafe's timezone in the database, only UTC offset, so we
 * convert the user's now time back to UTC and add the cafe's offset to get the
 * cafe's local 'now' time. We then compare that to the cafe's open and close
 * times to determine if the cafe is open, or if it is before opening time, or
 * after closing time. With this, we can determine what to display to the user.
 *
 * @param cafeHours
 * @param copy
 * @returns { string } ie: "Open until 5:00 PM" or "Opens Wed at 7:00 AM"
 */
export const getHoursPresentation = (
  cafeHours: CafeHoursModel[],
  copy: CafeHoursCopy,
): string => {
  // get the current UTC hour
  const utcNowHr: number = new Date().getUTCHours();

  // get utc minutes - ensure two digits for maths (3min = :03, not :3)
  const getMinimumTwoDigits = (num: number): string =>
    num.toString().length === 1 ? `0${num}` : num.toString();
  const utcNowMin: string = getMinimumTwoDigits(new Date().getUTCMinutes());

  // get time as a float number for math (3:03 => 3.03)
  const utcNowTime: number = parseFloat(`${utcNowHr}.${utcNowMin}`);

  // get the cafe's UTC offset as a number - not all days have data so find
  // one with data first
  const cafeLocalTimeRep = cafeHours?.find((cH) => cH?.opensCafeLocalTime);
  const cafeUTCOffset: number = parseFloat(
    cafeLocalTimeRep?.opensCafeLocalTime?.slice(-6).replace(":", "."),
  );

  // ensure two decimals only
  const cafeLocalNowTime: number =
    Math.round(((utcNowTime + cafeUTCOffset) % 24) * 100) / 100;

  // if cafeLocalNowTime >= 24, it's tomorrow there and we need a day offset
  const dayOffset: number = utcNowTime + cafeUTCOffset >= 24 ? +1 : 0;

  // UTC today's number in Monday-Sunday
  const utcToday: number = getMonThruSunDay(new Date().getUTCDay());

  // day number in cafe local time Mon-Sun, accounting for week turnover
  const cafeToday: number =
    utcToday === 6 && dayOffset === +1 ? 0 : utcToday + dayOffset;

  // check if cafe is open right now
  const cafeIsOpen: boolean =
    cafeHours?.[cafeToday]?.opensCafeLocalTime &&
    cafeHours?.[cafeToday]?.closesCafeLocalTime &&
    cafeLocalNowTime >=
      getLocationLocalTime(cafeHours?.[cafeToday]?.opensCafeLocalTime) &&
    cafeLocalNowTime <
      getLocationLocalTime(cafeHours?.[cafeToday]?.closesCafeLocalTime);

  // get when the cafe closes today, in the cafe's local time
  const cafeCloseTime = cafeHours?.[cafeToday]?.closesCafeLocalTime
    ? `${copy.openTil} ${format(cafeHours?.[cafeToday]?.closes, "p")}`
    : undefined;

  // find the next time the cafe opens, in the cafe's local time
  const getNextOpenTime = () => {
    const openDaysHrs = cafeHours?.filter((cH) => cH?.opensCafeLocalTime);
    if (
      cafeLocalNowTime <
      getLocationLocalTime(cafeHours?.[cafeToday]?.opensCafeLocalTime)
    ) {
      // include today
      const nextOpenDayHrsInclusive =
        openDaysHrs?.find((day) => day?.day >= cafeToday) ?? openDaysHrs[0];
      const nextOpenTimeInclusive = nextOpenDayHrsInclusive?.opens
        ? `${copy.opens} ${format(nextOpenDayHrsInclusive?.opens, "ccc p")}`
        : undefined;
      return nextOpenTimeInclusive;
    }
    // else do not include today
    const nextOpenDayHrs =
      openDaysHrs?.find((day) => day?.day > cafeToday) ?? openDaysHrs[0];
    const nextOpenTime = nextOpenDayHrs?.opens
      ? `${copy.opens} ${format(nextOpenDayHrs?.opens, "ccc p")}`
      : undefined;
    return nextOpenTime;
  };

  // if the cafe is open right now, display today's closing time, else
  // display the next open time
  const hoursDisplay = cafeIsOpen ? cafeCloseTime : getNextOpenTime();

  return hoursDisplay;
};
