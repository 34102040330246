export const fetcher = (...args) =>
  // @ts-ignore
  fetch(...args).then((res) => res.json());

export const fetchWithToken = (url: string, token: string) =>
  fetch(url, {
    mode: "cors",
    headers: {
      Authorization: token,
    },
  })
    .then((res) => res.json())
    .catch();
