import {
  CafeLocatorCopy,
  CafeLocatorProps,
} from "@bluebottlecoffee/design-system/components";
import { CafeDetailLocatorCopy } from "../../components/CafeDetailLocator";
import { Cafe, CafeLocator } from "../sanity-schema";
import { CafeSearchResult } from "../../components/CafeSearch/CafeSearchResult";
import { CafeHoursCopy } from "./cafe-card";
import { CafeInstantSearchProps } from "../../components/CafeSearch/CafeSearch";

export interface CafeLocatorFullCopy
  extends CafeLocatorCopy,
    CafeHoursCopy,
    CafeDetailLocatorCopy {
  goToSearchPage: string;
  noCafesHeadline: string;
  noCafesDescription: string;
  viewMoreText: string;
}

type RenderedCafeLocatorProps = Omit<
  CafeLocatorProps,
  | "copy"
  | "locationSearchFormProps"
  | "queryString"
  | "searchBox"
  | "totalResultsDisplay"
  | "viewMoreButton"
  | "onModuleSearch"
> & {
  copy: CafeLocatorFullCopy;
};

export type DereferencedCafeLocator = Omit<CafeLocator, "previewCafes"> & {
  previewCafes: Cafe[];
};

type CafeLocatorTransformerProps = {
  data: DereferencedCafeLocator;
  isModule?: boolean;
  lang: string;
  region: string;
};

export function toCafeLocatorProps({
  data,
  isModule,
  lang,
  region,
}: CafeLocatorTransformerProps): RenderedCafeLocatorProps {
  const { copy } = data;
  return {
    bgColor: data.bgColor ?? undefined,
    copy: {
      headline: copy.headline[lang],
      imgLinkGenericAriaText: copy.imgLinkGenericAriaText[lang],
      missingCafes: copy.missingCafes[lang],
      newWindowWarning: copy.newWindowWarning[lang],
      noCafesFound: copy.noCafesFound[lang],
      noCafesHeadline: copy.noCafesHeadline[lang],
      noCafesDescription: copy.noCafesDescription[lang],
      opens: copy.opens[lang],
      openTil: copy.openTil[lang],
      searchButton: copy.searchButton[lang],
      searching: copy.searching[lang],
      searchInputPlaceholder: copy.searchInputPlaceholder[lang],
      showingResults: copy.showingResults[lang],
      goToSearchPage: copy.goToSearchPage[lang],
      viewMoreText: copy.viewMoreText[lang],
    },
    isModule,
    previewCafes: data.previewCafes.length
      ? data.previewCafes?.map((cafe) => (
          <li key={cafe._id}>
            <CafeSearchResult
              cafeCardProps={{
                imageAriaLabel: copy.imgLinkGenericAriaText[lang],
                newWindowWarning: copy.newWindowWarning[lang],
              }}
              cafeHoursCopy={{
                opens: copy.opens[lang],
                openTil: copy.openTil[lang],
              }}
              cafe={cafe}
              isModule={isModule}
              lang={lang}
              region={region}
            />
          </li>
        ))
      : [],
  };
}

export function toCafeSearchProps({
  data,
  isModule,
  lang,
  region,
}: CafeLocatorTransformerProps): CafeInstantSearchProps {
  return {
    isModule,
    cafeLocatorProps: toCafeLocatorProps({ data, isModule, lang, region }),
    lang,
    region,
  };
}
