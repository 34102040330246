import useSWR from "swr";
import { addDays, startOfWeek } from "date-fns";
import { fetcher } from "../fetcher";
import { CafeHoursModel } from "./models";
import { CafeHoursData } from "../../../shared-types";

export type CafeHoursParams = {
  cafeId: string;
};

type DayIndex = 0 | 1 | 2 | 3 | 4 | 5 | 6;

/**
 * Takes in an index by day (Monday: 0, ..., Sunday : 6) and returns the date
 * of the week based on the date provided.
 *
 * @desiredDayIndex value should be 0-6 inclusive and represent the index of a
 * day of the week where Monday is 0 and Sunday is 6.
 */
function getDateOfDay(dateInWeek: Date, desiredDayIndex: DayIndex): Date {
  const monday = startOfWeek(dateInWeek, {
    weekStartsOn: 1,
  });

  return addDays(monday, desiredDayIndex);
}

/**
 * Get the hours of the week for a cafe
 *
 * There will always be 7 days for each week. If a day of the week isn't
 * present in the data returned from OP, we create the day with no open/close
 * hours.
 *
 * */
export function useCafeHours({ cafeId }: CafeHoursParams): CafeHoursModel[] {
  if (!cafeId) {
    throw Error(
      "This cafe has not been set up correctly. No id was provided in the Sanity _id. Please reach out to engineering for assistance.",
    );
  }

  const base = process.env.NEXT_PUBLIC_CLOUDFLARE_WORKER_API_HOST || "";
  const endpoint = `${base}/api/v1/cafes/hours?cafeId=${cafeId}`;

  const { data: hoursData } = useSWR(
    [endpoint],
    (url) => fetcher(url) as Promise<CafeHoursData[]>,
  );

  const maybeFullWeekHours =
    hoursData?.map((cafeHours) => new CafeHoursModel(cafeHours)) ?? [];

  const mondayThroughSundayDayOfWeekIndex: DayIndex[] = [0, 1, 2, 3, 4, 5, 6];

  const dateInWeek =
    maybeFullWeekHours.find((model) => !!model.date)?.date ?? new Date();

  return mondayThroughSundayDayOfWeekIndex.map((weekDayIndex) => {
    const matchingDay = maybeFullWeekHours.find(
      ({ day: cafeDay }) => cafeDay === weekDayIndex,
    );

    return (
      matchingDay ??
      new CafeHoursModel({
        id: "0000",
        type: "retail_cafe_hours",
        attributes: {
          date: getDateOfDay(dateInWeek, weekDayIndex).toISOString(),
          closes: null,
          opens: null,
        },
      })
    );
  });
}
