import { CafeHoursData } from "../../../../shared-types";
import { getMonThruSunDay } from "../../../utils/get-hours-presentation";

export class CafeHoursModel {
  date: Date;

  day: number;

  closes?: Date;

  closesCafeLocalTime?: string;

  opens?: Date;

  opensCafeLocalTime?: string;

  // create a getter that checks to see if either the closes or opens is null
  // if either is null, then the cafe is closed
  get isClosed(): boolean {
    return !this.closes || !this.opens;
  }

  constructor({ attributes: { date, closes, opens } }: CafeHoursData) {
    // Set time to midnight to account for timezones
    this.date = new Date(`${date.split("T")[0]}T00:00:00`);

    this.day = getMonThruSunDay(this.date.getDay());

    if (opens && closes) {
      this.closesCafeLocalTime = closes;
      this.opensCafeLocalTime = opens;
      this.closes = CafeHoursModel.getLocalCafeDateTime(closes);
      this.opens = CafeHoursModel.getLocalCafeDateTime(opens);
    }
  }

  /** Get a Date representation of the cafe's local time
   *
   * The cafe hours are stored as the local time of the cafe with the timezone
   * offset where that cafe is located. It is NOT stored at UTC. Because we
   * always want to show it in the cafe's local time, we need to strip off the
   * timezone offset and create a Date object that ignores timezones. Otherwise,
   * the time would always be shown in the user's local time.
   *
   * This function makes an assumption that all cafe hours are stored in the
   * same format.
   *
   * @param dateString - A date string in the format of "YYYY-MM-DDTHH:MM:SS-07:00"
   * @returns A Date object representing the cafe's local time
   */
  static getLocalCafeDateTime(dateString: string | null): Date | undefined {
    if (!dateString) return undefined;

    const timezoneOffsetLength = 6; // e.g. -07:00
    const localCafeDateString = dateString.substring(
      0,
      dateString.length - timezoneOffsetLength,
    );

    return new Date(localCafeDateString);
  }
}
