import { FunctionComponent } from "react";
import {
  CafeCard,
  CafeCardProps,
  useScreenSizeContext,
} from "@bluebottlecoffee/design-system/components";
import { Cafe as CafeType } from "@bluebottlecoffee/design-system/components/lib/types";
import {
  CafeHoursCopy,
  toCafeCardProps,
} from "../../lib/transformers/cafe-card";
import { AlgoliaCafe } from "../../lib/algolia/types";
import { CafeHoursModel, useCafeHours } from "../../lib/integrations/op";
import { Cafe as CafeSchema } from "../../lib/sanity-schema";
import { getCafeId } from "../../lib/utils";

type CafeSearchResultProps = (
  | {
      cafe: CafeSchema;
      algoliaHit?: never;
    }
  | {
      cafe?: never;
      algoliaHit: AlgoliaCafe;
    }
) & {
  cafeCardProps: Omit<CafeCardProps, "cafe">;
  cafeHoursCopy: CafeHoursCopy;
  isModule: boolean;
  lang: string;
  region: string;
  isCafeSearch?: boolean;
};

export const CafeSearchResult: FunctionComponent<CafeSearchResultProps> = ({
  algoliaHit,
  cafe,
  cafeCardProps,
  cafeHoursCopy: copy,
  isModule,
  lang,
  region,
  isCafeSearch,
}) => {
  const { isDesktop, isLgTablet } = useScreenSizeContext();
  const isLgViewport: boolean = isDesktop() || isLgTablet();

  const id: string = cafe ? cafe._id : algoliaHit.objectID;
  const cafeId: string = getCafeId(id);
  const hours: CafeHoursModel[] = useCafeHours({ cafeId });

  const data = cafe ?? algoliaHit;
  const transformedCafe: CafeType = toCafeCardProps({
    copy,
    data,
    lang,
    region,
    hours,
    isCafeSearch,
  });

  return (
    <CafeCard
      {...cafeCardProps}
      cafe={transformedCafe}
      layout={isLgViewport && !isModule ? "landscape" : "portrait"}
    />
  );
};
