import { Cafe } from "@bluebottlecoffee/design-system/components/lib/types";
import { bbcLogoFallBackImgSrc } from "../utils";
import { AlgoliaCafe as AlgoliaCafeSchema } from "../algolia/types";
import { Address, Cafe as CafeSchema } from "../sanity-schema";
import { toImageProps } from "./image";
import { cafePage } from "../link-builders";
import { CafeHoursModel } from "../integrations/op";
import { getLocale } from "../integrations/date-fns/get-locale";
import { toHoursProps } from "./detail-page-overview";
import { getHoursPresentation } from "../utils/get-hours-presentation";

export type CafeHoursCopy = {
  opens?: string;
  openTil?: string;
};

type CafeTransformerProps = {
  copy: CafeHoursCopy;
  data: AlgoliaCafeSchema | CafeSchema;
  lang: string;
  region: string;
  hours?: CafeHoursModel[];
  isCafeSearch?: boolean;
};

/**
 * formats an address object into a string for a url param search
 * @param {Address} address
 * @param {string} name
 * @returns {string} - ie: "The+Walker+396+Broadway+New+York+NY+10013+US"
 */
export const getUrlSearchFormat = (address: Address, name: string): string =>
  [
    name,
    address.street,
    address.extended,
    address.locality,
    address.district,
    address.postalCode,
    address.countryCode,
  ]
    .map((a) => a?.replaceAll(" ", "+").replaceAll(",", ""))
    .filter((a) => typeof a === "string" && a?.length)
    .join("+");

export function toCafeCardProps({
  copy,
  data,
  lang,
  region,
  hours: cafeHours,
  isCafeSearch,
}: CafeTransformerProps): Cafe {
  const { address, geoLocation, name } = data;
  const locale = getLocale({ region, lang });
  const hours = toHoursProps({ cafeHours, locale }) ?? [];

  return {
    address: {
      street: address.street,
      extended: address.extended,
      locality: address.locality,
      district: address.district,
      postalCode: address.postalCode,
      countryCode: address.countryCode,
      default: true, // required in the type but not used here
    },
    addressLink: `
      https://www.google.com/maps/search/?api=1&query=Blue+Bottle+Coffee+${getUrlSearchFormat(
        address,
        name[lang],
      )}
    `,
    country: data.country,
    geopoint: {
      alt: geoLocation.alt,
      lat: geoLocation.lat,
      lng: geoLocation.lng,
    },
    hours,
    hoursPresentation: getHoursPresentation(cafeHours, copy) ?? "",
    image: data.image
      ? toImageProps(data.image, lang)
      : { src: bbcLogoFallBackImgSrc, altText: "" },
    name: name[lang],
    region: data.region[lang],
    slug: data.slug.current,
    slugLink: `${cafePage({ region, lang, slug: data.slug.current })}${
      isCafeSearch ? "?search=true" : ""
    }`,
  };
}
