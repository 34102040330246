import { FunctionComponent, useState } from "react";
import {
  InstantSearch,
  useConfigure,
  UseConfigureProps,
} from "react-instantsearch";
import {
  Button,
  CafeLocator,
  CafeLocatorProps,
} from "@bluebottlecoffee/design-system/components";
import usePlacesAutocompleteService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useRouter } from "next/router";
import { centerOfUSAStr } from "../../lib/component-utils/cafe-search-fns";
import { CafeLocatorFullCopy } from "../../lib/transformers/cafe-locator";
import { publicSearchClient } from "../../lib/algolia/clients";
import {
  cafesIndexSearchableAttribute,
  standardizeIndexName,
} from "../../lib/algolia/indices";
import { CafeSearchHits } from "./CafeSearchHits";

export interface CafeInstantSearchProps {
  lang: string;
  region: string;
  isModule?: boolean;
  cafeLocatorProps: Omit<
    CafeLocatorProps,
    | "copy"
    | "locationSearchFormProps"
    | "queryString"
    | "totalResultsDisplay"
    | "viewMoreButton"
  > & {
    copy: CafeLocatorFullCopy;
  };
}

export const CafeInstantSearch: FunctionComponent<CafeInstantSearchProps> = ({
  lang,
  region,
  isModule,
  cafeLocatorProps,
}) => {
  const router = useRouter();
  useConfigure({
    restrictSearchableAttributes: cafesIndexSearchableAttribute(lang),
    distinct: true,
    clickAnalytics: true,
    hitsPerPage: 999,
    aroundLatLng: router.query.geometry ?? centerOfUSAStr, // gets an initial radial search but is overridden by insideBoundingBox when defined
  } as UseConfigureProps);

  const [showPredictions, setShowPredictions] = useState<boolean>(true);

  const {
    isQueryPredictionsLoading,
    queryPredictions: queryPredictionsUnfiltered,
    placesService,
    getQueryPredictions,
  } = usePlacesAutocompleteService({
    apiKey: process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY,
    options: {
      input: "",
      language: lang,
      region,
    },
  });

  // filter out predictions that are not places
  const queryPredictions: google.maps.places.QueryAutocompletePrediction[] =
    queryPredictionsUnfiltered.filter((qp) => qp.place_id);

  /**
   * gets called inside handleSearchSubmit() if predictions exist but have not
   * been selected
   */
  const handleNoSelectedPrediction = async (
    prediction: google.maps.places.QueryAutocompletePrediction,
  ): Promise<string[]> =>
    new Promise((resolve, reject) => {
      const { place_id: placeId } = prediction;
      placesService.getDetails({ placeId }, (result, status) => {
        if (status !== "OK") {
          setShowPredictions(false);
          const error = new Error(
            `An error occurred: ${prediction?.description}`,
          );
          reject(error);
        } else {
          resolve([
            result.formatted_address,
            result.geometry.location.toString(),
          ]);
        }
      });
    });

  const handleSearchSubmit = async (queryPredictionArg?): Promise<string> => {
    if (isQueryPredictionsLoading) return;
    setShowPredictions(false);

    const navigateToUrl = (url: string) => {
      if (isModule) {
        router.push(url, undefined, { shallow: false });
      } else {
        router.replace(url, undefined, { shallow: true });
      }
    };

    const url = `/${region}/${lang}/cafes/search-results`;

    if (!queryPredictionArg) {
      navigateToUrl(url);
      return;
    }

    const [qStr, geoStr] = await handleNoSelectedPrediction(queryPredictionArg);

    const hashEncodedQuery = qStr.replace(/#/g, "%23");
    const qTypes = queryPredictionArg.types.join(",");
    const queryEncoded = encodeURI(
      `${url}?&query=${hashEncodedQuery}&geometry=${geoStr}&queryTypes=${qTypes}`,
    );

    navigateToUrl(queryEncoded);
  };

  const locationSearchFormProps: CafeLocatorProps["locationSearchFormProps"] = {
    address: "",
    addressPredictions: showPredictions && queryPredictions,
    isLoading: isQueryPredictionsLoading,
    goToSearchPage: cafeLocatorProps.copy.goToSearchPage,
    showPredictions: !isQueryPredictionsLoading,
    onSelectPrediction: (prediction) => handleSearchSubmit(prediction),
    onSearchSubmit: async () => {
      await handleSearchSubmit(queryPredictions[0]);
    },
    onSearchInput: (searchInput) => {
      setShowPredictions(true);
      getQueryPredictions({
        input: searchInput,
      });
    },
  };

  return isModule ? (
    <CafeLocator
      {...cafeLocatorProps}
      totalResultsDisplay={3}
      locationSearchFormProps={locationSearchFormProps}
      viewMoreButton={
        <Button
          level="primary"
          text={cafeLocatorProps.copy.viewMoreText}
          onClick={() => {}}
        />
      }
    />
  ) : (
    <CafeSearchHits
      copy={cafeLocatorProps.copy}
      lang={lang}
      region={region}
      locationSearchFormProps={locationSearchFormProps}
    />
  );
};

export const CafeSearch: FunctionComponent<CafeInstantSearchProps> = (
  props,
) => (
  <InstantSearch
    indexName={standardizeIndexName("cafes")}
    searchClient={publicSearchClient}
    insights
  >
    <CafeInstantSearch {...props} />
  </InstantSearch>
);
