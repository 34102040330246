import { Locale } from "date-fns";
import * as caLocale from "date-fns/locale/en-CA";
import * as usLocale from "date-fns/locale/en-US";
import * as jpLocale from "date-fns/locale/ja";
import * as krLocale from "date-fns/locale/ko";
import { Dialect } from "../../utils/locale";
import localization from "../../../localization";

// Builds a locale string from a dialect object.
//
// The dialect can be retrieved from the router.
export function getLocale({ region: currentRegion }: Dialect): Locale {
  const regionLocales = {
    us: usLocale.default,
    ca: caLocale.default,
    jp: jpLocale.default,
    kr: krLocale.default,
  };
  const configuredRegions = Object.keys(regionLocales);

  const hasAllRegionsConfigured = localization
    .regionNames()
    .every((region) => configuredRegions.includes(region));

  if (!hasAllRegionsConfigured) {
    throw new Error(
      `Not all regions are configured. An update is required to pull in the
     correct locale from the date-fns package.`,
    );
  }

  return regionLocales[currentRegion];
}
