import { FunctionComponent, useCallback, useEffect, useState } from "react";
import {
  Icon,
  useScreenSizeContext,
} from "@bluebottlecoffee/design-system/components";
import {
  AdvancedMarker,
  Map,
  MapCameraChangedEvent,
  MapCameraProps,
  MapEvent,
  useMap,
} from "@vis.gl/react-google-maps";
import { centerOfUSA } from "../../lib/component-utils/cafe-search-fns";

export type CafeMarker = {
  name: string;
  position: {
    lat: number;
    lng: number;
  };
};

export type CafeSearchMapProps = {
  cafeMarkers?: CafeMarker[];
  initialCenter?: google.maps.LatLngLiteral;
  center?: google.maps.LatLngLiteral;
  initialZoom: number;
  onIdle: (map: MapEvent<unknown>) => void;
  onMapSelectedMarkerChange: (cafeMarkerName?: string) => void;
  cafeMarkerSelected: string;
};

/**
 * Utilizes react-google-maps package, renders Map component that displays cafe
 * markers and allows user to interact with the map and select a cafe
 */
export const CafeSearchMap: FunctionComponent<CafeSearchMapProps> = ({
  cafeMarkers,
  initialCenter,
  initialZoom,
  onIdle,
  onMapSelectedMarkerChange,
  cafeMarkerSelected,
}) => {
  const { isDesktop, isLgTablet, isMobile } = useScreenSizeContext();

  const googleMapId: string = "cafe-locator-google-map";
  // use api hook to get map object and set camera movement to pan rather than
  // jump when the mapCenter changes
  const map = useMap(googleMapId);

  const [cameraProps, setCameraProps] = useState<MapCameraProps>({
    center: initialCenter,
    zoom: initialZoom,
  });

  // keep the Map's camera props in sync with the map's camera
  const handleCameraChange = useCallback(
    (e: MapCameraChangedEvent) => {
      setCameraProps(e.detail);
    },
    [setCameraProps],
  );

  const handleMarkerClick = (cafeMarker: CafeMarker): void => {
    onMapSelectedMarkerChange(cafeMarker.name);
    // avoid zooming back out if the user has already zoomed in and only
    // zoom in if the current view is below the minToZoom level
    const zoomMinThreshold: number = 11;
    const zoomToLevel: number = 12;
    if (cameraProps.zoom < zoomMinThreshold) {
      setCameraProps({ ...cameraProps, zoom: zoomToLevel });
    }
    const scrollMobileThreshold: number = 250;
    const scrollDesktopThreshold: number = 150;
    if (isMobile() && window.scrollY < scrollMobileThreshold) {
      // scroll down to reveal the cafe card list below the map
      window.scrollTo({ top: scrollMobileThreshold, behavior: "smooth" });
    } else if (
      (isDesktop() || isLgTablet()) &&
      window.scrollY > scrollDesktopThreshold
    ) {
      // scroll up to reveal the map above the cafe card list
      window.scrollTo({ top: scrollDesktopThreshold, behavior: "smooth" });
    }

    map?.panTo(cafeMarker.position);
  };

  useEffect(() => {
    map?.setCenter(initialCenter ?? centerOfUSA);
  }, [map, initialCenter]);

  useEffect(() => {
    setCameraProps({ ...cameraProps, zoom: initialZoom });
  }, [initialZoom]);

  return (
    <Map
      {...cameraProps}
      defaultCenter={initialCenter ?? centerOfUSA}
      defaultZoom={initialZoom}
      disableDefaultUI
      id={googleMapId}
      gestureHandling="cooperative"
      maxZoom={18}
      minZoom={2}
      reuseMaps
      style={{
        width: "100%",
        height: "100%",
      }}
      zoomControl
      // mapId connects the map to custom style in google cloud console
      // https://console.cloud.google.com/google/maps-apis/studio/styles/381754a19710815e?project=blue-bottle-coffee-4b969
      mapId="e60112b7748353db"
      onIdle={onIdle}
      onCameraChanged={handleCameraChange}
    >
      {cafeMarkers?.map((cafeMarker, i) => (
        <AdvancedMarker
          clickable
          position={{
            lat: cafeMarker.position.lat,
            lng: cafeMarker.position.lng,
          }}
          onClick={() => handleMarkerClick(cafeMarker)}
          zIndex={cafeMarker.name === cafeMarkerSelected ? 80 : i + 5}
        >
          <Icon
            name="bbcMapPin"
            outline={cafeMarker.name === cafeMarkerSelected}
          />
        </AdvancedMarker>
      ))}
    </Map>
  );
};
CafeSearchMap.defaultProps = {
  cafeMarkers: undefined,
  initialCenter: centerOfUSA,
};
